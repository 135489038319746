import gql from 'graphql-tag';

export const GET_CART_PRODUCT_LIST = gql`
  query ($page: Int, $pageSize: Int) {
    getCartProducts(page: $page, pageSize: $pageSize) {
      id
      username
      business_name
      products {
        id
        cart_id
        name
        price
        is_on_sale
        sale_price
        slug
        quantity
        shipping_price
        pickup_address
        medias {
          id
          public_url
          file_type
          images {
            vendor_products
          }
        }
        store {
          id
          business_name
          profile_image
          currency
        }
      }
    }
  }
`;

export const CHECK_CART_EMPTY = gql`
  query {
    checkIfCartIsEmpty {
      status
    }
  }
`;

export const GET_CART_PRODUCTS_BY_STORE = gql`
  query ($storeUsername: String!) {
    getCartProductsByStore(storeUsername: $storeUsername) {
      id
      username
      business_name
      stripe_account_id
      products {
        id
        cart_id
        name
        price
        is_on_sale
        sale_price
        out_of_stock
        slug
        quantity
        shipping_price
        pickup_address
        medias {
          id
          public_url
          file_type
          images {
            vendor_products
          }
        }
        store {
          id
          business_name
          profile_image
          currency
        }
      }
    }
  }
`;
